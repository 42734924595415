export const environment = {
    production: false,
    env: 'dev',
    transApi: `https://trans.dev.gravystackapp.com/v2-admin`,
    usersApi: `https://user-management.dev.gravystackapp.com/v2`,
    customerPaymentsApi: `https://customer-payments.dev.gravystackapp.com/v2`,
    bankingApi: `https://banking.dev.gravystackapp.com/v2`,
    userPortalSessionKey: "AG_USR",
    rolPortalSessionKey: "AG_USR_ROLES",
    userTokenKey: "AG_TOKEN", 
    userSessionChallenge:"AG_SESSION_CHALLENGE",
    synapseDashboardHostname:"uat-dashboard.synapsefi.com",
    amplitudeDashboardHostname:"app.amplitude.com",
    amplitudeDashboardProjectId:"402975", 
    agentRoleId: '35545674-6f90-49c2-b316-16bc0c2e0db0',
    managerRoleId: 'd20277f4-58fc-443c-ab75-d21019d1384f',
    administratorRoleId: '931386c7-0880-4bbf-8c9f-af593185efdc',
};
